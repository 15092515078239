<template>
  <section class="custom-section home-more-info">
    <div class="container">
      <button
        @click="toggleShowMoreInfo"
        class="btn primary"
      >
        {{ labelShowMoreButton }}
      </button>
      <div
        v-if="showMoreInfo"
      >
        <div class="home-more-info__content">
          <div>
            <h2>{{$t('homeMoreInfo.block2.title')}}</h2>
            <span>{{$t('homeMoreInfo.block2.text')}}</span>
          </div>
          <div>
            <h2>{{$t('homeMoreInfo.block3.title')}}</h2>
            <span>{{$t('homeMoreInfo.block3.text')}}</span>
          </div>
          <div>
            <h3>{{$t('homeMoreInfo.block4.title')}}</h3>
            <span>{{$t('homeMoreInfo.block4.text')}}</span>
          </div>
          <div>
            <h3>{{$t('homeMoreInfo.block5.title')}}</h3>
            <span>{{$t('homeMoreInfo.block5.text')}}</span>
          </div>
          <div>
            <h3>{{$t('homeMoreInfo.block6.title')}}</h3>
            <span>{{$t('homeMoreInfo.block6.text')}}</span>
          </div>
          <div>
            <h3>{{$t('homeMoreInfo.block7.title')}}</h3>
            <h4>1. {{ $t('homeMoreInfo.block7.firstStep.title') }}</h4>
            <ul>
              <li>{{ $t('homeMoreInfo.block7.firstStep.point1') }}</li>
              <li>{{ $t('homeMoreInfo.block7.firstStep.point2') }}</li>
            </ul>
            <h4>2. {{ $t('homeMoreInfo.block7.secondStep.title') }}</h4>
            <ul>
              <li>{{ $t('homeMoreInfo.block7.secondStep.point1')}}</li>
              <li>{{ $t('homeMoreInfo.block7.secondStep.point2')}}</li>
              <li>{{ $t('homeMoreInfo.block7.secondStep.point3')}}</li>
            </ul>
            <h4>3. {{ $t('homeMoreInfo.block7.thirdStep.title') }}</h4>
            <ul>
              <li>{{ $t('homeMoreInfo.block7.thirdStep.point1') }}</li>
              <li>{{ $t('homeMoreInfo.block7.thirdStep.point2') }}</li>
            </ul>
          </div>
          <div>
            <h2>{{ $t('homeMoreInfo.block8.title') }}</h2>
            <span>{{ $t('homeMoreInfo.block8.text') }}</span>
          </div>
          <div>
            <h3>{{ $t('homeMoreInfo.block9.title') }}</h3>
            <ul>
              <li>{{ $t('homeMoreInfo.block9.point1') }}</li>
              <li>{{ $t('homeMoreInfo.block9.point2') }}</li>
              <li>{{ $t('homeMoreInfo.block9.point3') }}</li>
            </ul>
            <span>{{ $t('homeMoreInfo.block9.text') }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const { t } = useI18n();

const showMoreInfo = ref(false);

const toggleShowMoreInfo = function() {
  showMoreInfo.value = !showMoreInfo.value;
}

const labelShowMoreButton = computed(() => {
  return showMoreInfo.value ? t('homeMoreInfo.showButton.hide') : t('homeMoreInfo.showButton.show')
})
</script>

<style scoped lang="scss">
.home-more-info {
  padding-top: 30px;
}
.home-more-info__content {
  h2 {
    font-size: 36px;
    margin: 20px 0;
    text-align: left;
  }
  h3 {
    font-size: 36px;
    margin: 20px 0;
  }
  ul {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1024px) {
  .home-more-info__content {
    h2 {
      font-size: 24px;
      margin: 20px 0;
    }
    h3 {
      font-size: 24px;
      margin: 18px 0;
    }
  }
}
@media screen and (max-width: 640px) {
  .home-more-info {
    padding-top: 20px;
  }
  .home-more-info__content {
    h2 {
      font-size: 20px;
      margin: 15px 0;
    }
    h3 {
      font-size: 20px;
      margin: 15px 0;
    }
  }
}
</style>
